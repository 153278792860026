import { FragmentDefiner, useReadFragment } from "../../../../../GlobalHooks/useReadFragment";
import { useContext, useEffect, useState } from "react";

import { ConfigStateContext } from "../../../../../Contexts/Configurations/context";
import { PropComponentMode } from "../../../PropertyInterfaces";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { ModalState } from "../../TextForm/type";

const REQUEST_WPP_MUTATION = gql`
	mutation request_wpp_mutation($property_id: Int!) {
		requestPhone(property_id: $property_id, isWpp: true)
	}
`;

export interface OwnerWspInterface {
	id: number | string;
	mode: PropComponentMode;
	disabled?: boolean;
	queryRouter?: string;
	onWpClick?: () => void;
	forceState?: ModalState;
}

export const FRAGMENT_HAS_WHATSAPP = new FragmentDefiner(
	"Property",
	`
    title
    link
    owner {
        id
        name
        has_whatsapp
    }
    operation_type {
    	id
    	name
    }
`
);

export const useWhatsppButton = (propId: number | string) => {
	const { main_domain, country_code } = useContext(ConfigStateContext);
	const [wppMessage, setWppMessage] = useState<string>("");
	const { data } = useReadFragment(FRAGMENT_HAS_WHATSAPP, propId);
	const [mutation, { loading, data: wppMutation }] = useMutation(REQUEST_WPP_MUTATION, {
		onError: error => {
			console.error("User Unauthenticated.");
		},
	});
	const [wppNumber, setWppNumber] = useState(undefined);

	useEffect(() => {
		if (wppMutation?.requestPhone) {
			let number = wppMutation?.requestPhone;
			setWppNumber(number)
		}
	}, [wppMutation]);

	useEffect(() => {
		if (data?.title && data?.link) {
			setWppMessage(
				encodeURIComponent(`Hola, ví esta propiedad en ${country_code!=='ce3'?'InfoCasas': 'Casas en el Este'} y me gustaría tener más información. ${data?.title} https://${main_domain}/${data?.link}`)
			);
		}
	}, [data]);

	let LINK_WPP =
		wppNumber && wppMessage
			? `https://api.whatsapp.com/send?phone=${wppNumber}&text=${wppMessage}&app_absent=1`
			: null;

	//nota: como el telefono se guarda en un state, no en cache de apolo. cada ver teléfono va a hacer la mutation por separado
	const viewWpp = () => {
		return mutation({
			variables: {
				property_id: propId,
			},
		});
	};

	return {
		viewWpp,
		loading,
		wppMessage,
		linkWpp: LINK_WPP,
		owner_name: data?.owner.name,
		wppPhone: wppNumber,
		prop_title: data ? data.title : "",
		operation_type_name: data && data?.operation_type?.name ? data.operation_type.name : "",
		hasWhatsapp: data ? data.owner.has_whatsapp : false,
	};
};

