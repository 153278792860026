import { FragmentDefiner, useReadFragment } from "../../../../../GlobalHooks/useReadFragment";

import { PropComponentMode } from "../../../PropertyInterfaces";
import { gql } from "@apollo/client/core";
import { useMutation } from "@apollo/client";
import { ModalState } from "../../TextForm/type";

const REQUEST_PHONE_MUTATION = gql`
	mutation request_phone_mutation($property_id: Int!) {
		requestPhone(property_id: $property_id)
	}
`;

export interface OwnerPhoneInterface {
	id: number | string;
	type?: "callButton" | "viewPhone";
	mode: PropComponentMode;
	disabled?: boolean;
	property_info?: any;
	seller?: boolean;
	queryRouter?: string;
	icon?: boolean;
	btnType?: "text" | "default";
	onPhoneClick?: () => void;
	forceState?: ModalState;
}

export const FRAGMENT_MASKED_PHONE = new FragmentDefiner(
	"Property",
	`
	title
	seller {
		id
		name
		masked_phone
	}
    owner {
        id
        name
        masked_phone
    }
    operation_type {
    	id
    	name
    }
`
);

export const usePhoneButton = ({ property_id, seller = false }) => {
	const [mutation, { loading, data: mutationData, called }] = useMutation(
		REQUEST_PHONE_MUTATION,
		{
			onError: error => {},
		}
	);

	const { data } = useReadFragment(FRAGMENT_MASKED_PHONE, property_id);

	//nota: como el telefono se guarda en un state, no en cache de apolo. cada ver teléfono va a hacer la mutation por separado
	const viewPhone = () => {
		return mutation({
			variables: {
				property_id,
			},
		});
	};

	let masked_phone = seller ? data?.seller?.masked_phone : data?.owner?.masked_phone;
	masked_phone = masked_phone || data?.owner?.masked_phone;

	return {
		viewPhone,
		owner_name: data?.owner.name,
		masked_phone: masked_phone,
		prop_title: data ? data.title : "",
		operation_type_name: data && data?.operation_type?.name ? data.operation_type.name : "",
		phone: mutationData?.requestPhone,
		loading,
		called,
	};
};




