import { gql, useLazyQuery } from "@apollo/client";
import { useFilters } from "../../../Components/Filters/Filters.hook";
import { useQuery } from "@apollo/client";
import { QUERY_AVAILABLE_FILTERS } from "shared-components/GQL";
import { useContext, useEffect } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { currencyType } from "shared-components/ViewFragments/SearchResults/Sider/TopBarFilters/options";

const QUERY_SEARCH_URL = gql`
	query searchUrl($params: SearchParamsInput!) {
		searchUrl(params: $params) {
			url
		}
		error
	}
`;

export const useHomeFilters = () => {
	const { filters, changeFilters, filtersTags } = useFilters();	
	const { country_code } = useContext(ConfigStateContext);
	const doQuery = useQuery(QUERY_AVAILABLE_FILTERS);
	const currencyValue = currencyType.find(v=>v.countries.includes(country_code))?.value

	useEffect(()=>{
		let operationID
		if(filters.operation_type_id===1){
			operationID=1
			const value = {text:'U$S',value:operationID}
			changeFilters({currencyID:value,m2Currency:value})
		}
		else{
			operationID=currencyValue
			const value = {text:'',value:operationID}
			changeFilters({currencyID:value,m2Currency:value})
		}
	},[filters.operation_type_id])
	const [getSearchURL, response] = useLazyQuery(QUERY_SEARCH_URL, {
		onError: errURL => console.error("ERROR", errURL),
	});

	return {
		filters,
		changeFilters,
		filtersTags,
		search: {
			send: getSearchURL,
			response: response,
		},
	};
};

