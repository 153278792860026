
import { ConsultasFeature, FavoritosFeature, FiltrosFeature, NotificacionFeature } from "shared-components/Components/CustomIcons/MoveToTheApp/web";
import "./styles.less";

const dataApp = {
    features: [
        {
            img: <NotificacionFeature />,
            text: 'Recibí notificaciones a medida que aparezcan nuevas oportunidades.'
        },
        {
            img: <FiltrosFeature />,
            text: 'Filtrá de la manera más precisa y encontrá tu casa más fácil.'
        },
        {
            img: <ConsultasFeature />,
            text: 'Consultá directamente desde la app con respuestas en tiempo real.'
        },
        {
            img: <FavoritosFeature />,
            text: 'Guardá tus favoritos y recibí notificaciones si bajan de precio.'
        }
    ],
    buttons: [
        {
            img: "https://cdn2.infocasas.com.uy/web/64401d3288d5f_google.png",
            name: 'Google Play',
            link: 'http://play.google.com/store/apps/details?id=uy.com.infocasas.infoapp'
        },
        {
            img: "https://cdn2.infocasas.com.uy/web/64401d1329fdd_apple.png",
            name: 'App Store',
            link: 'http://itunes.apple.com/uy/app/infocasas/id1126880888?mt=8'
        }
    ]
}
const BannerAppCampaign = () => {
    return (
        <section className="contenedor">
            <div className="bannerAppCampaign">
                <div className="bannerAppCampaign__features">
                    <p>
                        ¡Encontrá tu casa con la app de InfoCasas!
                    </p>
                    <h3>Tu nuevo hogar a una descarga de distancia</h3>
                    <ul className="bannerAppCampaign__features--items">
                        {dataApp.features.map((e, i) => {
                            return <li key={`appFeature-${i}`}>{e.img}{e.text}</li>
                        })}
                    </ul>
                    <div className="bannerAppCampaign__features__links">
                        {dataApp.buttons.map((e, i) => { return (<a key={`button-link-${i}`} href={e.link} target='_blank'><img width="141" height="43" src={e.img} alt={'Botón a la tienda de aplicaciones de ' + e.name} /></a>) })}
                    </div>
                </div>
                <div className="bannerAppCampaign__image">
                    <img width="263" height="383" className="bannerAppCampaign__image--mobile" src="https://cdn2.infocasas.com.uy/web/th.outside12200x480.646baea82b82e_mobile_mini_app_mobile.png" alt="imagen de un Celular con la app de Infocasas" />
                    <img width="263" height="383" className="bannerAppCampaign__image--desktop" src="https://cdn2.infocasas.com.uy/web/th.outside1200x480.646bac6a8b8fd_qr.png" alt="imagen de un celular con la app de infocasas y un QR para ir a la tienda de aplicaciones" />
                </div>                
            </div>
            <div className="orangeBubble"></div>
        </section>
    )
}

export default BannerAppCampaign