import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * @url https://github.com/xnimorz/use-debounce
 * @param callback
 * @param delay
 * @param options
 */
export function useDebouncedCallback<T extends unknown[]>(
    callback: (...args: T) => unknown,
    delay: number,
    options: { maxWait?: number; leading?: boolean; trailing?: boolean } = {}
): [(...args: T) => void, () => void, () => void] {
    const maxWait = options.maxWait;
    const maxWaitHandler = useRef(null);
    const maxWaitArgs = useRef<T | []>([]);

    const leading = options.leading;
    const trailing = options.trailing === undefined ? true : options.trailing;
    const leadingCall = useRef(false);

    const functionTimeoutHandler = useRef(null);
    const isComponentUnmounted = useRef(false);

    const debouncedFunction = useRef(callback);
    debouncedFunction.current = callback;

    const cancelDebouncedCallback: () => void = useCallback(() => {
        clearTimeout(functionTimeoutHandler.current);
        clearTimeout(maxWaitHandler.current);
        maxWaitHandler.current = null;
        maxWaitArgs.current = [];
        functionTimeoutHandler.current = null;
        leadingCall.current = false;
    }, []);

    useEffect(
        () => () => {
            // we use flag, as we allow to call callPending outside the hook
            isComponentUnmounted.current = true;
        },
        []
    );

    const debouncedCallback = useCallback(
        (...args: T) => {
            maxWaitArgs.current = args;
            clearTimeout(functionTimeoutHandler.current);
            if (leadingCall.current) {
                leadingCall.current = false;
            }
            if (!functionTimeoutHandler.current && leading && !leadingCall.current) {
                debouncedFunction.current(...args);
                leadingCall.current = true;
            }

            functionTimeoutHandler.current = setTimeout(() => {
                let shouldCallFunction = true;
                if (leading && leadingCall.current) {
                    shouldCallFunction = false;
                }
                cancelDebouncedCallback();

                if (!isComponentUnmounted.current && trailing && shouldCallFunction) {
                    debouncedFunction.current(...args);
                }
            }, delay);

            if (maxWait && !maxWaitHandler.current && trailing) {
                maxWaitHandler.current = setTimeout(() => {
                    const args = maxWaitArgs.current;
                    cancelDebouncedCallback();

                    if (!isComponentUnmounted.current) {
                        debouncedFunction.current.apply(null, args);
                    }
                }, maxWait);
            }
        },
        [maxWait, delay, cancelDebouncedCallback, leading, trailing]
    );

    const callPending = useCallback(() => {
        // Call pending callback only if we have anything in our queue
        if (!functionTimeoutHandler.current) {
            return;
        }

        debouncedFunction.current.apply(null, maxWaitArgs.current);
        cancelDebouncedCallback();
    }, [cancelDebouncedCallback]);

    // At the moment, we use 3 args array so that we save backward compatibility
    return [debouncedCallback, cancelDebouncedCallback, callPending];
}

function valueEquality<T>(left: T, right: T): boolean {
    return left === right;
}

export function useDebounce<T>(
    value: T,
    delay: number,
    options?: { maxWait?: number; leading?: boolean; trailing?: boolean; equalityFn?: (left: T, right: T) => boolean }
): [T, () => void, () => void] {
    const eq = options && options.equalityFn ? options.equalityFn : valueEquality;

    const [state, dispatch] = useState(value);
    const [callback, cancel, callPending] = useDebouncedCallback(
        useCallback((value: T) => dispatch(value), []),
        delay,
        options
    );
    const previousValue = useRef(value);

    useEffect(() => {
        // We need to use this condition otherwise we will run debounce timer for the first render (including maxWait option)
        if (!eq(previousValue.current, value)) {
            callback(value);
            previousValue.current = value;
        }
    }, [value, callback, eq]);

    return [state, cancel, callPending];
}