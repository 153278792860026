import "./styles.less";

import { Carousel, Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";

import { ImagenOptimizada as Img } from "../../../Components/Image/web";
import { useBranInfo } from "./hook";
import { useTheme } from "../../../Styles/ThemeHook";

const { Title, Paragraph } = Typography;

export const BrandInfo = () => {
	const { theme } = useTheme();
	const { data } = useBranInfo();

	const [settings, setSettings] = useState({
		render: true,
		arrows: false,
		responsive: [],
		speed: 400,
		autoplaySpeed: 6000,
		autoplay: false,
		slidesToShow: 3,
		slidesToScroll: 1,
	});

	useEffect(() => {
		setSettings({
			...settings,
			render: false,
			responsive: [
				{
					breakpoint: Number(
						theme.breakPoints.md.substring(0, theme.breakPoints.md.length - 2)
					),
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						dots: true,
						autoplay: true,
						autoplaySpeed: 6000,
					},
				},
				{
					breakpoint: Number(
						theme.breakPoints.sm.substring(0, theme.breakPoints.sm.length - 2)
					),
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						dots: true,
						autoplay: true,
						autoplaySpeed: 6000,
					},
				},
			],
		});
	}, []);

	useEffect(() => {
		if (!settings.render) setSettings({ ...settings, render: true });
	}, [settings.render]);
	if (!settings.render) return null;
	if(!data.length) return null;

	return (<>
		<div className="container brand-info-container">
			<Title className="ta-center" level={2}>
				{"Mirá como InfoCasas te puede ayudar"}
			</Title>
			<div className="brand-info">
				<Carousel {...settings}>
					{data.map((item, i) => (
							<div key={"brandInfo_item_" + i}>
								<Row justify={"center"}>
									<>
										<Col span={24}>
											<Img
												src={item.imgUrl}
												alt={item.title}
												width="150px"
												height="130px"
												className={"brand-info-image"}
											/>
										</Col>
										<Col span={24}>
											<Title className="brand-info-title" level={3}>
												{item.title}
											</Title>
										</Col>
										<Col span={24}>
											<Paragraph className="brand-info-text">
												{item.paragraph}
											</Paragraph>
										</Col>
									</>
								</Row>
							</div>
						))}
				</Carousel>
			</div>
		</div>
	</>);
};
