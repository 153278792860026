import "./styles.less";

import { Col, Form, Row } from "antd";
import {
	KeywordLocation,
	setRecientLocations,
} from "../../../Components/Filters/KeywordLocation/web";
import React, { useEffect } from "react";
import { isTemporal, showPropertyType } from "../../../Utils/Functions";

import { OperationType } from "../../../Components/Filters/OperationType/web";
import { PropertyType } from "../../../Components/Filters/PropertyType/web";
import { TemporalFilter } from "../../../Components/Filters/TemporalFilter/web";
import { encodeHashUrl } from "../../../Utils/Functions";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useHomeFilters } from "./HomeFilters.hook";
import { useRouter } from "next/router";
import { useTheme } from "../../../Styles/ThemeHook";
import { useTemporalFilter } from "shared-components/Components/Filters/TemporalFilter/TemporalFilter.hook";

export const HomeFilters = () => {
	const { filters, changeFilters, filtersTags, search } = useHomeFilters();

	const doSearch = () => search.send({ variables: { params: filters } });

	const screens = useBreakpoint();
	const { theme } = useTheme();
	const router = useRouter();

	const showOperationFilter = true;
	const showTemporalFilter = isTemporal(filters.operation_type_id);
	const showPropertyFilter = showPropertyType(filters.operation_type_id);

	const {
		setShowSeasonInput,
		showSeasonInput,
	} = useTemporalFilter();

	const handleChangeSeason = (show) => {
		setShowSeasonInput(show)
	}

	useEffect(() => {
		if (filters.estate_id != null || filters.searchstring != null || filters.maxPrice != null) {
			search.send({ variables: { params: filters } });
		}
	}, [filters.estate_id, filters.searchstring, filters.maxPrice]);

	useEffect(() => {
		if (search.response.data && !search.response.loading) {
			setRecientLocations(filtersTags.neighborhood_id, filtersTags.estate_id);
			router.push(
				{
					pathname: "/searchPage",
					query: { hashed: encodeHashUrl({ filters: filtersTags }) },
				},
				search.response.data.searchUrl.url
			);
		}
	}, [search.response]);

	return (
		<Form onFinish={doSearch} className="home-filters home-filters-IC">
			<Row
				justify={"center"}
				gutter={[
					{
						xs: theme.spacing.mdSpacing,
						sm: theme.spacing.mdSpacing,
						lg: 0,
					},
					{
						xs: theme.spacing.mdSpacing,
						sm: theme.spacing.mdSpacing,
						lg: theme.spacing.smSpacing,
					},
				]}>

				{/* OPERTATION TYPE FILTER */}
				{(showOperationFilter) && (
					<Col
						xs={showTemporalFilter ? 24 : 12}
						sm={showTemporalFilter ? 24 : 12}
						lg={22}>
						<OperationType
							filterChanged={changeFilters}
							home
							inputType={screens.lg ? "butons" : "select"}
							handleChangeSeason={handleChangeSeason}
							showSeasonInput={showSeasonInput}
						/>
					</Col>
				)}

				{/* PROPERTY TYPE FILTER */}
				{showPropertyFilter && (
					<Col xs={12} sm={12} lg={8} className="big-input">
						<PropertyType filterChanged={changeFilters} />
					</Col>
				)}

				{/* TEMPORAL FILTER */}
				{showTemporalFilter && (
					<Col xs={24} sm={24} lg={10} className="big-input temporal-filter-container">
						<TemporalFilter filterChanged={changeFilters} showSeasonInput={showSeasonInput} handleChangeSeason={handleChangeSeason} />
					</Col>
				)}

				<Col flex={"1"} className="big-input">
					{/* KEYWORD - LOCATION FILTER */}
					<KeywordLocation
						filterChanged={changeFilters}
						searchByReference={true}
						searchByProject={false}
						isSearchForHome={true}
					/>
				</Col>
			</Row>
		</Form>
	);
};
