import "./styles.less";

import { Card, Typography } from "antd";

import { ConfigStateContext } from "../../../../Contexts/Configurations/context";
import { ImagenOptimizada as Img } from "../../../../Components/Image/web";
import { removeUrlProtocol } from "../../../../Utils/Functions";
import { useContext } from "react";

const { Meta } = Card;
const { Title, Link, Paragraph } = Typography;

interface NewsSectionProps {
	news: any;
	size?: "default" | "large";
}

export const News = ({ news, size = "default" }: NewsSectionProps) => {
	const { country_code } = useContext(ConfigStateContext);
	const cardTitle = (
		<Title
			className="newsDescription"
			ellipsis={size == "large" ? { rows: 2 } : { rows: 3 }}
			level={size == "large" ? 2 : 4}>
			{news.title}
		</Title>
	);

	const cardDescription = <Paragraph ellipsis={{ rows: 2 }}>{news.description}</Paragraph>;

	const cardImage = (
		<div className="article-image">
			<Img src={news.image} alt={news.title} /> 
		</div>
	);

	const link = removeUrlProtocol(`/blog/${news.slug}`);

	return (
		<div className={"article" + (size == "large" ? " postLarge" : " postSmall")}>
			<Link className="article-link" title={news.title} href={link} target="_blank">
				<Card className={"news-card"} bordered={false} hoverable={false} cover={cardImage}>
					<Meta
						title={size == "large" && cardTitle}
						description={size == "large" ? cardDescription : cardTitle}
					/>
				</Card>
			</Link>
		</div>
	);
};
